
import React from 'react';
import { MDXRenderer } from 'gatsby-plugin-mdx';

import Global from '../layout/global';
import { graphql } from 'gatsby';

import './blog-post.scss';

/**
 * Custom template that renders block posts
 */
const BlogTemplate = ({ path, data }) => {
	const post = data.mdx;
	let img = '';
	if (post.frontmatter.featuredimage) {
		img = post.frontmatter.featuredimage.childImageSharp.sizes.src;
	} else {
		img = '';
	}
	return (
		<Global
			page="blog-post"
			isBlogPost={ true }
			title={ post.frontmatter.title }
			image={ img }
			url={ path }
			background="/img/background/homepage3.jpg"
            keywords={ post.frontmatter.keywords !== null && post.frontmatter.keywords }
			description={ post.frontmatter.subtitle || post.excerpt }>
			{
				post.frontmatter.featuredimage &&
				<div className="g-blog-post-featured">
					<div className="g-blog-post-featured-inner" style={{
						backgroundImage: `url(${ post.frontmatter.featuredimage.childImageSharp.sizes.src })`
					}} />
				</div>
			}
			<section className="c-blog-post g-box-outline">
				<a className="c-blog-post-view-all" href="/blog">&laquo; View all posts</a>
				<h2>
					<span>{ post.frontmatter.title }</span>
				</h2>
				<div className="g-blog-details">
					Posted by <b>{ post.frontmatter.author }</b> on <b>{ post.frontmatter.date }</b>
				</div>
				<div className="c-blog-content">
					<MDXRenderer>{ post.body }</MDXRenderer>
				</div>
			</section>
		</Global>
	);
};

export const pageQuery = graphql`
	query BlogPostBySlug($slug: String!) {
		mdx(fields: { slug: { eq: $slug } }) {
			id
			excerpt(pruneLength: 160)
			body
			frontmatter {
				title
				date(formatString: "MMMM DD, YYYY")
				description
				subtitle
				keywords
				author
				background
				featuredimage {
					childImageSharp {
					    sizes(maxWidth: 1500) {
					 	    ...GatsbyImageSharpSizes
					    }
					}
				}
			}
		}
	}
`;

export default BlogTemplate;
